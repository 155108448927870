import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import Statements from "@components/_molecules/statements"

const StatementsPage = ({ location }) => {
  const { statements } = data
  const investmentAccountId =
    location.state !== null && typeof window !== `undefined`
      ? location.state.investmentAccountId
      : ``
  return (
    <Layout type="app">
      <SEO title={statements.title} />
      <Statements data={{ investmentAccountId: investmentAccountId }} />
    </Layout>
  )
}

export default StatementsPage
