import React, { Component } from "react"
import "./react-tabs.scss"
import "./style.scss"
import BackPage from "@components/_molecules/back_parent_dashboard"
import * as data from "@data/pledge"
import axios from "axios"
import * as headers from "@data/headers"
import * as errors from "@services/errors"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import StatementsMonthly from "@components/_molecules/statements_monthly"
import StatementsConfirms from "@components/_molecules/statements_confirms"
import StatementsTaxForms from "@components/_molecules/statements_taxforms"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"

class Statements extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statementsMonthly: null,
      statementsConfirms: null,
      statementsTaxforms: null
    }
  }

  render() {
    const { statements } = data

    return (
      <section className="standard">
        <div className="heading-size">
          <BackPage data={statements.heading} />
        </div>
        <Tabs>
          <div className="tab-bar">
            <TabList>
              <Tab>
                <span role="img" aria-label="Document">
                  📄
                </span>
                &nbsp; Statements
              </Tab>
              <Tab>
                <span role="img" aria-label="Handshake">
                  🤝
                </span>
                &nbsp; Trade Confirmations
              </Tab>
              <Tab>
                <span role="img" aria-label="Money">
                  💸
                </span>
                &nbsp; Tax Forms
              </Tab>
            </TabList>
          </div>
          <div className="statements">
            <TabPanel>
              <StatementsMonthly
                parent={this}
                data={{ statementsMonthly: this.state.statementsMonthly }}
              />
            </TabPanel>
            <TabPanel>
              <StatementsConfirms
                parent={this}
                data={{ statementsConfirms: this.state.statementsConfirms }}
              />
            </TabPanel>
            <TabPanel>
              <StatementsTaxForms
                parent={this}
                data={{ statementsTaxforms: this.state.statementsTaxforms }}
              />
            </TabPanel>
            <GeneralSubmitMessage error={this.state.generalError} />
          </div>
        </Tabs>
      </section>
    )
  }
}
export default Statements
