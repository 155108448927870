import React, { Component } from "react"
import "./style.scss"

class StatementsConfirms extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const list = (statement, index) => (
      <div className="statement-line" key={index}>
        <div className="entry">
          <div className="statement">
            <p className="statement-name">{statement.displayName}</p>
          </div>
        </div>
        <div>
          <a className="download" href="" onClick={ev => this.props.parent.downloadFileKey(ev, statement.fileKey)}>Download</a>
        </div>
      </div>
    )

    return (
      <div>
        <div>{
          this.props.data.statementsConfirms ?
            this.props.data.statementsConfirms.length ?
              this.props.data.statementsConfirms.map((statement, index) =>
                list(statement, index)
              ) :
            <div className="no-statements">There are no trade confirmations for this account yet</div>
          : <div className="loading">loading...</div>
        }</div>
      </div>
    )
  }
}

export default StatementsConfirms